import React from 'react';

import Layout from '../components/Layout';
import Sitewidth from '../components/Sitewidth';
import { Title, Paragraph } from '../components/Text';

const NotFoundPage = () => (
  <Layout>
    <Sitewidth>
      <Title>NOT FOUND</Title>
      <Paragraph>
        You just hit a route that doesn&#39;t exist... the sadness.
      </Paragraph>
    </Sitewidth>
  </Layout>
);

export default NotFoundPage;
